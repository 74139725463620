<div class="box signin-box px-5">
  <h1 class="mb-4 text-center">Sign in</h1>
  <button class="mb-3" pButton type="button" (click)="login()">
    <div class="text-center w-100">
      <i class="pi pi-google p-button-icon p-button-icon-left"></i>
      <span class="p-button-label">Sign in with Google</span>
    </div>
  </button>
  <button class="mb-3" pButton type="button" disabled>
    <div class="text-center w-100">
      <i class="pi pi-twitter p-button-icon p-button-icon-left"></i>Sign in with
      Twitter
    </div>
  </button>
  <button class="mb-3" pButton type="button" disabled>
    <div class="text-center w-100">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="16"
        height="16"
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 2400 2800"
        xml:space="preserve"
        class="me-2"
      >
        <style type="text/css">
          .st0 {
            fill: #ffffff;
          }
        </style>
        <title>Asset 3</title>
        <g>
          <polygon
            class="st0"
            points="2200,1300 1800,1700 1400,1700 1050,2050 1050,1700 600,1700 600,200 2200,200"
          />
          <g>
            <g id="Layer_1-2">
              <path
                d="M500,0L0,500v1800h600v500l500-500h400l900-900V0H500z M2200,1300l-400,400h-400l-350,350v-350H600V200h1600V1300z"
              />
              <rect x="1700" y="550" width="200" height="600" />
              <rect x="1150" y="550" width="200" height="600" />
            </g>
          </g>
        </g>
      </svg>
      <span>Sign in with Twitch </span>
    </div>
  </button>
</div>
