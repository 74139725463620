<section class="text-center jumbotron">
  <div class="container px-4 py-5 my-5">
    <h1 class="fw-bold">YGO Draft</h1>

    <p class="lead mb-4">Draft Cards. Test Pulls. Build Decks.</p>

    <p class="lead mb-4">
      This Website is still in development. Data will be wiped regularly. There
      will be bugs. Send me a message if you have any feedback.
    </p>

    <button
      routerLink="/drafting"
      pButton
      type="button"
      label="Get Started"
    ></button>
  </div>
</section>
