<header class="navbar navbar-expand-lg" role="banner">
  <div class="container-fluid">
    <!-- <a routerLink="/">
      <img
        width="40"
        alt="Site Logo"
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNTAgMjUwIj4KICAgIDxwYXRoIGZpbGw9IiNERDAwMzEiIGQ9Ik0xMjUgMzBMMzEuOSA2My4ybDE0LjIgMTIzLjFMMTI1IDIzMGw3OC45LTQzLjcgMTQuMi0xMjMuMXoiIC8+CiAgICA8cGF0aCBmaWxsPSIjQzMwMDJGIiBkPSJNMTI1IDMwdjIyLjItLjFWMjMwbDc4LjktNDMuNyAxNC4yLTEyMy4xTDEyNSAzMHoiIC8+CiAgICA8cGF0aCAgZmlsbD0iI0ZGRkZGRiIgZD0iTTEyNSA1Mi4xTDY2LjggMTgyLjZoMjEuN2wxMS43LTI5LjJoNDkuNGwxMS43IDI5LjJIMTgzTDEyNSA1Mi4xem0xNyA4My4zaC0zNGwxNy00MC45IDE3IDQwLjl6IiAvPgogIDwvc3ZnPg=="
      />
    </a> -->
    <div class="topbar-link">
      <a routerLink="/">YGO Draft</a>
    </div>
    <div class="spacer"></div>

    <ul class="topbar-menu">
      <li class="topbar-link">
        <a routerLink="/drafting">Drafting</a>
      </li>
      <li class="topbar-link" *ngIf="isLoggedIn">
        <a routerLink="/collections">Collections</a>
      </li>
      <li class="topbar-link">
        <a routerLink="/deckbuilder">Deck Builder</a>
      </li>
    </ul>
    <button
      *ngIf="!isLoggedIn"
      routerLink="/login"
      pButton
      type="button"
      label="Sign In"
    ></button>
    <button
      *ngIf="isLoggedIn"
      pButton
      (click)="logout()"
      class="p-button-text"
      type="button"
      label="Log Out"
    ></button>
  </div>
</header>
